const global = {
  api: {
    // Url Publico
    baseURL: process.env.VUE_APP_API_URL,
    baseURLVetus: process.env.VUE_APP_API_URL_VETUS,
    // baseURL: "https://api.spmv.pettree.com.br/v1/public",
    // baseURLVetus: "https://api.spmv.pettree.com.br/vet1/public",
    // baseURL: "https://api.anclivepa-pb.pettree.com.br/v1/public",
    // baseURLVetus: "https://api.anclivepa-pb.pettree.com.br/vet1/public",
    // baseUrlChamefacil_api: "https://chamefacilpettreeapi2.prolins.com.br/api",
    // baseUrlChamefacil: "https://chamefacilpettree.prolins.com.br/api",

    // Homolog
    // baseURL: "https://api.spmv.homolog.prolins.com.br/v1/public",

    // Desenvolvimento
    // Dev Euler
    // baseURL:
    //   "https://d490-2804-7f7-e080-4839-8f11-30ed-2f3d-88ea.ngrok-free.app" +
    //   // "/anclivepa_pb_bugfix/public",
    //   "/anclivepa_pb_dev/public",

    // baseURLVetus: "https://api.spmv.homolog.prolins.com.br/vet1/public/api",

    baseUrlChamefacil: "",
    baseUrlChamefacil_api: "",
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
