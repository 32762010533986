<template>
  <div class="">
    <div class="p-lg-3 pb-1">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Recepção</h3>
      </div>
      <div class="btnEscolherfila">
        <v-btn
          small
          depressed
          color="success"
          :class="{ btn: loading }"
          @click="dialogLocal"
        >
          <span v-if="!loading">Local de atendimento</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-dialog
          v-model="dialogAlocarLocal"
          v-if="dialogAlocarLocal"
          max-width="500px"
        >
          <v-card>
            <v-card-title>Selecionar local de atendimento</v-card-title>
            <v-card-text class="">
              <v-autocomplete
                :items="selectFilas"
                v-model="filaAlocada"
                :rules="[
                  validationService.required('Campo obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                label="Selecione onde deseja atender"
                item-value="text"
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn color="gray" text @click="dialogAlocarLocal = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                color="warning"
                :disabled="!filaAlocada"
                @click="alocarLocal"
                class="dialogButtonEncaminhar"
              >
                <span>Alocar</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="d-flex ml-1" v-if="localAtendimento">
      <span>Local de atendimento: {{ localAtendimento }}</span>
    </div>
    <hr />

    <div class="top-section d-flex justify-content-between">
      <div class="senha-container d-flex flex-column w-50">
        <div>
          <h2>senha</h2>
          <h1>{{ infoPaciente.Senha ? infoPaciente.Senha : "-" }}</h1>
        </div>

        <div class="buttons d-flex justify-content-around">
          <v-btn
            small
            depressed
            :disabled="infoPaciente.Senha || !id_filaAlocada"
            color="success"
            :class="{ btn: loading }"
            @click="chamar"
          >
            <span v-if="!loading">Chamar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="primary"
            :class="{ btn: loading }"
            @click="tocar"
          >
            <span v-if="!loading">Tocar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="warning"
            :class="{ btn: loading }"
            @click="encaminhar"
          >
            <span v-if="!loading">Encaminhar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>

          <v-dialog
            v-model="dialogEncaminhar"
            v-if="dialogEncaminhar"
            max-width="500px"
          >
            <v-card>
              <v-card-title>Encaminhar</v-card-title>
              <!-- <v-card-text>
                <v-autocomplete
                  v-model="selectedTutor"
                  :loading="loadingSearch"
                  :items="tutores"
                  :search-input.sync="searchTutor"
                  @keydown.enter="searchTutorbyName(searchTutor)"
                  hide-no-data
                  hide-selected
                  label="Selecione um tutor"
                  item-text="nome"
                  return-object
                  v-if="!petEncaminhar"
                ></v-autocomplete>
              </v-card-text> -->

              <v-dialog
                max-width="470"
                v-if="pagamentoPendente"
                v-model="pagamentoPendente"
              >
                <template v-slot:default="dialog" color="red">
                  <v-card>
                    <v-toolbar color="error" class="text-h6" dark
                      >Pagamento Pendente</v-toolbar
                    >
                    <v-card-text>
                      <div class="pt-8">{{ mensagemPendencia }}</div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Ok</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <v-card-text>
                <v-text-field
                  v-if="infoPaciente.PetCodigo"
                  :value="infoPaciente.PetCodigo + ' - ' + infoPaciente.NomePet"
                  label="Pet"
                  disabled
                ></v-text-field>
                <v-autocomplete
                  v-model="selectedPet"
                  :loading="loadingSearch"
                  :items="pets"
                  :search-input.sync="searchPet"
                  @keydown.enter="searchPetById(searchPet)"
                  chips
                  hide-no-data
                  hide-selected
                  label="Pesquise um pet por Nome ou Id"
                  :item-text="getItemText"
                  return-object
                  v-else-if="!petEncaminhar"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.id_pet + ' - ' + item.nome_pet"
                      />
                      <v-list-item-subtitle
                        class="mt-1"
                        v-html="item.id_cliente + ' - ' + item.nome"
                      />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!-- <v-select
                  :items="pets"
                  v-model="selectedPet"
                  label="Selecione um pet"
                  item-text="nome"
                  return-object
                  v-if="!petEncaminhar"
                ></v-select> -->
                <v-text-field
                  v-else
                  :value="petEncaminhar.petId + ' - ' + petEncaminhar.nome_pet"
                  label="Pet"
                  disabled
                ></v-text-field>
              </v-card-text>

              <v-card-text>
                <v-radio-group v-model="urgencia" row>
                  <v-radio value="1"></v-radio>
                  <span class="ml-n5 mt-1">Normal</span>
                  <v-radio value="4" class="ml-8"></v-radio>
                  <span class="ml-n5 mt-1">Urgente</span>
                </v-radio-group>
              </v-card-text>

              <textarea
                rows="4"
                cols="57"
                class="p-2 border mb-5"
                placeholder="Observações"
                maxlength="400"
                v-model="observacoes"
              ></textarea>

              <v-card-text>
                <v-autocomplete
                  :items="selectEncaminhar"
                  v-model="especialidadeSelecionada"
                  label="Selecione para onde deseja encaminhar"
                  item-value="text"
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
              </v-card-text>

              <v-card-actions>
                <v-btn color="gray" text @click="dialogEncaminhar = false">
                  Fechar
                </v-btn>
                <v-btn
                  small
                  depressed
                  color="warning"
                  :disabled="
                    !selectedPet &
                      !petEncaminhar.nome_pet &
                      !infoPaciente.PetCodigo ||
                      !especialidadeSelecionada ||
                      loadingEncaminhar
                  "
                  @click="encaminharPara"
                  class="dialogButtonEncaminhar"
                >
                  <span v-if="!loadingEncaminhar">Encaminhar</span>
                  <v-progress-circular
                    v-else
                    :width="3"
                    :size="20"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="error"
            :class="{ btn: loading }"
            @click="finalizar"
          >
            <span v-if="!loading">Finalizar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="primary"
            :class="{ btn: loading }"
            @click="aguardar"
          >
            <span v-if="!loading">Aguardar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>

      <InfoChamada :key="infoChamadaKey" />
    </div>

    <div>
      <Fila
        :token="token"
        @chamarSenha="chamarSenhaLista($event)"
        :key="infoChamadaKey"
      />
    </div>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import Mixin from "@/mixins/vuex_mixin.js";
import axios from "axios";

import InfoChamada from "@/components/recepcao/infoChamada/infoChamada.vue";
import Fila from "@/components/recepcao/fila.vue";
import ValidationService from "@/services/validation_service.js";
import PacienteService from "@/services/paciente_service.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import PetsService from "@/services/pets_service";
import ComandaService from "@/services/comanda_service";
import RequisicaoExameService from "../services/requisicao_exame_service";
import AuthService from "@/services/auth_service";
import ChamefacilMixin from "@/mixins/chamefacil.js";

export default {
  components: {
    InfoChamada,
    Fila,
  },
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, ChamefacilMixin],
  data() {
    return {
      idRequisicaoProcedimento: 0,
      loading: false,
      loadingSearch: false,
      tokenChamefacil: "",
      idSenha: "",
      IdChamadaInterna: "",
      infoPaciente: {},
      observacoes: "",
      infoChamadaKey: 0,
      dialogAlocarLocal: false,
      dialogEncaminhar: false,
      loadingEncaminhar: false,
      tutor_id: "",
      pet_id: "",
      pets: [],
      selectedPet: "",
      tutores: [],
      selectedTutor: "",
      searchTutor: "",
      selectEncaminhar: [],
      especialidadeSelecionada: "",
      selectFilas: [],
      filaAlocada: "",
      id_filaAlocada: "",
      validationService: {},
      pagamentoPendente: false,
      mensagemPendencia: "",
      petService: PetsService.build(),
      comandaService: ComandaService.build(),
      requisicaoExameService: RequisicaoExameService.build(),
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      localAtendimento: "",
      urgencia: "1",
      petEncaminhar: {},
      searchPet: "",
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: false,
        desc: false,
      },
      services: {
        authService: AuthService.build(),
      },
    };
  },
  watch: {
    searchTutor(nome) {
      if (!nome || this.selectedTutor) {
        return;
      }
      this.searchTutorbyName(nome);
    },
    searchPet(id) {
      if (!id || id.length < 2) {
        return;
      }
      this.searchPetById(id);
    },
    // selectedTutor() {
    //   // console.log(this.selectedTutor.nome);
    //   // this.selectedPet = "";
    //   this.pets = this.selectedTutor.pets;
    //   console.log(this.pets);
    // },
  },
  async beforeMount() {
    window.onbeforeunload = function() {
      return "";
    };
    if (
      JSON.parse(sessionStorage.getItem("vuex")).perfil.id == 56 ||
      JSON.parse(sessionStorage.getItem("vuex")).perfil.id == 1
    ) {
      this.id_filaAlocada = localStorage.getItem("idFilaAlocada");
      this.localAtendimento = JSON.parse(
        localStorage.getItem("localAtendimento")
      );
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      this.infoPaciente = !localStorage.getItem("infoPaciente")
        ? {}
        : JSON.parse(localStorage.getItem("infoPaciente"));
      this.validationService = ValidationService;

      // if (!this.id_filaAlocada) this.dialogLocal();
      if (!this.id_filaAlocada && this.tokenChamefacil) this.dialogLocal();
    } else {
      this.logout();
    }
  },
  methods: {
    async logout() {
      let tokenChamefacil = await localStorage.getItem("tokenChamefacil");
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setUnidade({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");

      localStorage.removeItem("idFilaAlocada");
      localStorage.removeItem("dadosUsuario");
      localStorage.removeItem("idRequisicaoProcedimento");
      localStorage.removeItem("localAtendimento");
      localStorage.removeItem("especialidadeAtendimento");
      localStorage.clear();
      const headers = { token: tokenChamefacil };
      let senhaPresa = JSON.parse(localStorage.getItem("infoPaciente"));
      if (senhaPresa) {
        axios
          .get(
            global.api.baseUrlChamefacil_api +
              "/atendimento/aguardarinterna?chamadainternaId=" +
              senhaPresa.IdChamadaInterna,
            { headers }
          )
          .then((resp) => {
            console.log(resp.data);
            localStorage.removeItem("infoPaciente");
          });
      } else {
        localStorage.removeItem("infoPaciente");
      }
      axios
        .get(global.api.baseUrlChamefacil + "/acesso/sair", { headers })
        .then((resp) => {
          console.log(resp);
          localStorage.removeItem("tokenChamefacil");
        });
    },
    teste() {
      console.log(this.petEncaminhar);
    },
    getItemText(item) {
      return `${item.id_pet} - ${item.nome_pet}`;
    },
    async searchPetById(search) {
      // console.log(id);
      const number = isNaN(search);
      console.log(number);

      const paginationParams = {
        page: this.options.page,
        // per_page: this.options.itemsPerPage,
        per_page: 1000,
        sort: this.options.order,
        desc: this.options.desc,
        search: search,
        filterId: !number,
      };
      this.loadingSearch = true;
      await this.petService
        .getPetsPaginatedList(paginationParams)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pets } = data;
          console.log(pets);
          this.pets = pets;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    },
    searchTutorbyName(nome) {
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
              console.log(this.tutores);
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    listarFilasDisponiveis() {
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      const headers = { token: this.tokenChamefacil };
      axios
        .get(global.api.baseUrlChamefacil + "/LocalChamada/ListarDisponiveis", {
          headers,
        })
        .then(async (resp) => {
          console.log(resp.data);
          this.selectFilas = await resp.data.localChamada.map(function(local) {
            return { id: local.Id, text: local.Nome };
          });
        });
    },
    async dialogLocal() {
      await this.listarFilasDisponiveis();
      this.dialogAlocarLocal = true;
    },
    alocarLocal() {
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      const headers = { token: this.tokenChamefacil };
      const alocarFila = this.selectFilas.filter(
        (item) => item.text === this.filaAlocada
      );
      this.id_filaAlocada = alocarFila[0].id;

      axios
        .post(
          global.api.baseUrlChamefacil + "/localchamada/alocarlocal",
          { IdLocal: this.id_filaAlocada },
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          localStorage.setItem(
            "idFilaAlocada",
            JSON.stringify(this.id_filaAlocada)
          );
          localStorage.setItem(
            "localAtendimento",
            JSON.stringify(this.filaAlocada)
          );
          this.localAtendimento = this.filaAlocada;
          this.dialogAlocarLocal = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    chamar() {
      const unidadeId = JSON.parse(sessionStorage.vuex).unidade.id;
      this.loading = true;
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/chamarproximainterna",
          {},
          { headers }
        )
        .then(async (resp) => {
          this.$_ACTIONS_setPetEncaminhar({});
          console.log(resp.data.Senha);
          this.infoPaciente = await resp.data.Senha;
          this.idChamadaInterna = resp.data.Senha.IdChamadaInterna;
          this.pet_id = this.infoPaciente.PetCodigo
            ? this.infoPaciente.PetCodigo
            : this.petEncaminhar
            ? this.petEncaminhar.petId
            : this.selectedPet.id_pet;

          if (resp.data.Senha.RequisicaoId) {
            this.idRequisicaoProcedimento = resp.data.Senha.RequisicaoId;
          } else if (this.pet_id) {
            this.idRequisicaoProcedimento = await this.requisicaoExameService
              .getRecepcaoRequisicaoPorSenhaChamefacil(
                this.infoPaciente.IdSenha,
                this.pet_id,
                unidadeId
              )
              .then((resp) => resp.json())
              .then((resp) => {
                return resp.data ? resp.data.id_requisicao : 0;
              });
          }
          this.infoPaciente.PetNome = await this.petService
            .getPetById(this.infoPaciente.PetCodigo)
            .then((resp) => {
              console.log(resp);
              return resp;
            });
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          localStorage.setItem(
            "idRequisicaoProcedimento",
            this.idRequisicaoProcedimento
          );
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    tocar() {
      this.loading = true;
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/tocarinterna?chamadaInternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.loading = false;
        });
    },
    async encaminhar() {
      this.petEncaminhar = "";
      const petEncaminhar = await this.$_GETTERS_petEncaminhar;

      if (petEncaminhar.nome_pet) {
        this.petEncaminhar = petEncaminhar;
        console.log(this.petEncaminhar);
      }

      this.idRequisicaoProcedimento =
        localStorage.getItem("idRequisicaoProcedimento") || 0;
      this.especialidadeSelecionada = "";
      this.selectedTutor = "";
      this.selectedPet = "";
      this.observacoes = "";
      this.loading = true;
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/localChamada/ListarFilasInternas?id=" +
            this.id_filaAlocada,
          { headers }
        )
        .then(async (resp) => {
          this.selectEncaminhar = await resp.data.localChamada.map(function(
            local
          ) {
            return { id: local.Id, text: local.Nome };
          });
          this.loading = false;
          this.dialogEncaminhar = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    async encaminharPara() {
      this.loadingEncaminhar = true;
      const unidadeId = await JSON.parse(sessionStorage.vuex).unidade.id;
      const petId = this.infoPaciente.PetCodigo
        ? this.infoPaciente.PetCodigo
        : this.petEncaminhar.petId
        ? this.petEncaminhar.petId
        : this.selectedPet.id_pet;

      const pendenciasComanda = await this.comandaService.intensComandaAbertos(
        petId,
        unidadeId
      );

      if (pendenciasComanda.status == 200) {
        this.pagamentoPendente = true;
        this.mensagemPendencia =
          "Foram encontrados itens em aberto. Favor realizar o pagamento.";
        this.loadingEncaminhar = false;
        return;
      } else {
        let usuarioNome = JSON.parse(
          sessionStorage.vuex
        ).usuario.dados.nome.split(" ");
        usuarioNome = `${usuarioNome[0]} ${
          usuarioNome[1] ? usuarioNome[1] : ""
        }`;
        this.tutor_id = this.infoPaciente.TutorCodigo
          ? this.infoPaciente.TutorCodigo
          : this.petEncaminhar
          ? this.petEncaminhar.tutorId
          : this.selectedPet.id_cliente;
        this.pet_id = this.infoPaciente.PetCodigo
          ? this.infoPaciente.PetCodigo
          : this.petEncaminhar
          ? this.petEncaminhar.petId
          : this.selectedPet.id_pet;

        const nomePet = this.infoPaciente.NomePet
          ? this.infoPaciente.NomePet
          : this.petEncaminhar.nome_pet
          ? this.petEncaminhar.nome_pet
          : this.selectedPet.nome_pet;
        const nomeTutor = this.infoPaciente.NomeTutor
          ? this.infoPaciente.NomeTutor
          : this.petEncaminhar.nome_tutor
          ? this.petEncaminhar.nome_tutor
          : this.selectedPet.nome;
        // let nomeUsuario = JSON.parse(sessionStorage.getItem('vuex').usuario.dados.nome.split(""));
        const encaminharPara = this.selectEncaminhar.filter(
          (item) => item.text === this.especialidadeSelecionada
        );
        const id_filaInterna = encaminharPara[0].id;
        const realocarFila = {};
        console.log(realocarFila);
        this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
        const headers = { token: this.tokenChamefacil };

        this.requisicaoExameService.setEncaminharRequisicaoPorSenhaChamefacil({
          chamadaInternaId: this.infoPaciente.IdChamadaInterna,
          filaInternaId: id_filaInterna,
          tutorId: this.tutor_id,
          petId: this.pet_id,
          requisicaoId: this.idRequisicaoProcedimento,
          senhaId: this.infoPaciente.IdSenha,
          unidadeId,
        });
        axios
          .post(
            global.api.baseUrlChamefacil_api + "/Atendimento/RealocarInternaV2",
            {
              chamadaInternaId: this.infoPaciente.IdChamadaInterna,
              filaInternaId: id_filaInterna,
              TutorCodigo: this.tutor_id,
              PetCodigo: this.pet_id,
              RequisicaoId: 0,
              ObservacaoExtra: `${this.infoPaciente.ObservacaoExtra || ""}
             ${this.observacoes && "|" + usuarioNome + ":"} ${
                this.observacoes
              }`,
              NomePet: nomePet.replace(/['`´"]/g, ""),
              NomeTutor: nomeTutor.replace(/['`´"]/g, ""),
              Prioridade: parseInt(this.urgencia),
            },
            { headers }
          )
          .then((resp) => {
            if (resp.data.sucesso) {
              this.infoPaciente = {};
              localStorage.removeItem("infoPaciente");
              this.infoChamadaKey += 1;
              this.loadingEncaminhar = false;
              this.dialogEncaminhar = false;
              this.especialidadeSelecionada = "";
              this.IdChamadaInterna = "";
              localStorage.removeItem("idRequisicaoProcedimento");
              this.$_ACTIONS_setPetEncaminhar({});
            } else {
              this.loadingEncaminhar = false;
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.data.Mensagem,
                color: "error",
              });
            }
          })
          .catch((error) => {
            this.loadingEncaminhar = false;
            this.dialogEncaminhar = false;
            this.especialidadeSelecionada = "";
            console.log(error.request.statusText);
            this.$_ACTIONS_showSnackbarMessage({
              message: error.response.data.mensagem,
              color: "error",
            });
          });
      }
    },
    async aguardar() {
      const unidadeId = JSON.parse(sessionStorage.vuex).unidade.id;
      this.tutor_id = this.infoPaciente.TutorCodigo
        ? this.infoPaciente.TutorCodigo
        : this.petEncaminhar
        ? this.petEncaminhar.tutorId
        : this.selectedPet.id_cliente;
      this.pet_id = this.infoPaciente.PetCodigo
        ? this.infoPaciente.PetCodigo
        : this.petEncaminhar
        ? this.petEncaminhar.petId
        : this.selectedPet.id_pet;

      this.loading = true;
      this.idRequisicaoProcedimento = localStorage.getItem(
        "idRequisicaoProcedimento"
      );
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");

      const headers = { token: this.tokenChamefacil };

      if (this.pet_id & this.tutor_id) {
        await this.requisicaoExameService.setAguardarRequisicaoPorSenhaChamefacil(
          {
            chamadaInternaId: this.infoPaciente.IdChamadaInterna,
            filaInternaId: this.infoPaciente.IdFilaInterna,
            tutorId: this.tutor_id,
            petId: this.pet_id,
            requisicaoId: this.idRequisicaoProcedimento,
            senhaId: this.infoPaciente.IdSenha,
            unidadeId,
          }
        );
      }

      axios
        .get(
          global.api.baseUrlChamefacil_api +
            "/atendimento/aguardarinterna?chamadainternaId=" +
            this.infoPaciente.IdChamadaInterna,
          { headers }
        )
        .then(async (resp) => {
          if (resp.data.sucesso) {
            this.infoPaciente = {};
            localStorage.removeItem("infoPaciente");
            this.infoChamadaKey += 1;
            this.loading = false;
            localStorage.removeItem("idRequisicaoProcedimento");
            this.$_ACTIONS_setPetEncaminhar({});
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.data.Mensagem,
              color: "error",
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: error.response.data.mensagem,
            color: "error",
          });
        });
    },
    finalizar() {
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/finalizarinterna",
          {
            IdSenha: this.infoPaciente.IdSenha,
            IdLocal: 0,
            IdFilaInterna: 0,
            UsuarioSenha: "",
            UsuarioAtendente: "",
          },
          { headers }
        )
        .then((resp) => {
          if (resp.data.Sucesso) {
            console.log(resp.data);
            this.infoPaciente = {};
            localStorage.removeItem("infoPaciente");
            this.infoChamadaKey += 1;
            localStorage.removeItem("idRequisicaoProcedimento");
            this.$_ACTIONS_setPetEncaminhar({});
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.data.Mensagem,
              color: "error",
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },

    chamarSenhaLista(idChamada) {
      const unidadeId = JSON.parse(sessionStorage.vuex).unidade.id;
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil");

      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/chamarsenhainternaespecifica?chamadaInternaId=" +
            idChamada,
          {},
          { headers }
        )
        .then(async (resp) => {
          this.infoPaciente = resp.data.Senha;
          this.idChamadaInterna = resp.data.Senha.IdChamadaInterna;
          this.pet_id = this.infoPaciente.PetCodigo
            ? this.infoPaciente.PetCodigo
            : this.petEncaminhar
            ? this.petEncaminhar.petId
            : this.selectedPet.id_pet;
          if (resp.data.Senha.RequisicaoId) {
            this.idRequisicaoProcedimento = resp.data.Senha.RequisicaoId;
          } else if (this.pet_id) {
            this.idRequisicaoProcedimento = await this.requisicaoExameService
              .getRecepcaoRequisicaoPorSenhaChamefacil(
                this.infoPaciente.IdSenha,
                this.pet_id,
                unidadeId
              )
              .then((resp) => resp.json())
              .then((resp) => {
                return resp.data ? resp.data.id_requisicao : 0;
              });
          }

          console.log(this.infoPaciente);
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          localStorage.setItem(
            "idRequisicaoProcedimento",
            this.idRequisicaoProcedimento
          );
          this.infoChamadaKey += 1;
          this.loading = false;
        });
    },
    async getservidorChameFacil() {
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

      await this.services.authService
        .servidorChameFacil(unidadeId)
        .then(async (response) => {
          const resp = await response.json();

          return {
            status: response.status,
            resp,
          };
        })
        .then(({ status, resp }) => {
          if (status == 200) {
            this.setServidorChamefacil(resp.data.host, resp.data.api);
          }
        });
    },

    async getChamefacilToken() {
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

      await this.services.authService
        .tokenChamefacil(unidadeId)
        .then(async (response) => {
          const resp = await response.json();
          return {
            status: response.status,
            resp,
          };
        })
        .then(({ status, resp }) => {
          if (status == 200) {
            console.log(resp);
            localStorage.setItem("tokenChamefacil", resp.data.Token);
            this.tokenChamefacil = resp.data.Token;
          }
        });
    },

    // getTutores() {
    //   const pacienteService = new PacienteService();
    //   this.tutores = pacienteService.getPacienteByName(
    //     {
    //       onSucess: (status) => (body) => {
    //         if (status === 200) {
    //           this.tutores = body.data.pacientes;
    //           console.log(this.tutores);
    //         } else {
    //           this.tutores = [];
    //         }
    //       },
    //       onError: (error) => {
    //         console.error(error);
    //         this.tutores = [];
    //       },
    //       onEnd: () => {
    //         this.loadingSearch = false;
    //       },
    //     },
    //     ""
    //   );
    // },
  },
};
</script>

<style scoped>
h1 {
  font-size: 100px;
}
.buttons {
  flex-wrap: wrap;
}

.buttons .btn {
  height: 30px;
  width: 95px;
}
.dialogButtonEncaminhar {
  height: 20px;
  width: 120px;
}
.btnEscolherfila {
  position: absolute;
  top: 2.3rem;
  right: 1.5rem;
}
@media (max-width: 1080px) {
  .top-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .senha-container {
    flex-direction: row !important;
    gap: 2rem;
    margin-top: 1rem;
  }
  .buttons {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}
</style>
